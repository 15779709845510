/**
 * BusinessIntro
 * 
 * @Author: Focci
 * @Date: 2023-10-17 17:12:56
 * @Last Modified by: Focci
 * @Last Modified time: 2023-10-17 17:12:56
 */

// import PropTypes from 'prop-types'
import useLogin from '@hook/useLogin'
import CheckIcon from '@mui/icons-material/Check'
import { Button } from '@comp/material'
import { useTranslations } from 'next-intl'
import Image from 'next/image'
import artwork from '@img/artwork.jpg'

function BusinessIntroItem({
  title = '',
  meta = '',
}) {
  return (
    <div>
      <div className="flex items-center gap-x-1">
        <CheckIcon className="text-primary" />
        <span className="text-f.8">{title}</span>
      </div>
      <div className="text-meta ml-5 mt-1 max-w-full lg:max-w-[7rem]">
        {meta}
      </div>
    </div>
  )
}

export default function BusinessIntro() {
  const th = useTranslations('home')
  const tho = useTranslations('house')
  const { openSigninDialog } = useLogin()

  return (
    <div 
      className="
        rounded-sm shadow-simple p-4 flex justify-around gap-6
        flex-col items-start md:flex-row md:items-center
      "
    >
      <div className="w-[401px] max-w-full">
        <Image
          priority
          src={artwork.src || ''}
          width={401}
          height={245}
          alt="Hougarden Business Introduction"
        />
      </div>
      <div>
        <div className="text-f1.2 font-medium">
          {th('propertyInfoorganized')}
        </div>
        <div className="mt-4 grid grid-cols-1 lg:grid-cols-3 gap-x-10 gap-y-4">
          <BusinessIntroItem 
            title={th('recordViewedProperties')}
            meta={th('quicklyFindRecentlyViewed')}
          />
          <BusinessIntroItem 
            title={th('favoritesPreview')}
            meta={th('propertiesCategorized')}
          />
          <BusinessIntroItem 
            title={tho('claimProperty')}
            meta={th('getGovValuationInfo')}
          />
        </div>
        <div className="mt-6">
          <Button onClick={openSigninDialog}>
            <span className="py-0.5 px-8 text-f.8">
              {th('viewNow')}
            </span>
          </Button>
        </div>
      </div>
    </div>
  )
}

// BusinessIntro.propTypes = {
//   className: PropTypes.string,
// }
