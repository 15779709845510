/**
 * HomeTags
 * 
 * @Author: Focci
 * @Date: 2024-06-11 14:34:52
 * @Last Modified by: Focci
 * @Last Modified time: 2024-06-11 14:34:52
 */

'use client'

// import PropTypes from 'prop-types'
import useFetch from '@hook/useFetch'
import useLogin from '@hook/useLogin'
import { isArray } from 'lodash-es'
import { Skeleton } from '@comp/material'
import HomeItemScoff from '../HomeItemScoff'
import RecommendTags from './RecommendTags'

export default function HomeTags() {
  const { isLogin } = useLogin()
  const { data, isLoading } = useFetch('recommend/homepage', { isLogin }, {
    swrOptions: {
      revalidateIfStale: true,
      revalidateOnFocus: true,
      keepPreviousData: true,
    }
  })
  
  if(!isLoading && (!data?.items || (isArray(data?.items) && data.items.length === 0))) {
    return null
  }

  return (
    <HomeItemScoff 
      title={!isLoading ? data?.title : <Skeleton className="w-1/2" />}
    >
      <RecommendTags data={data?.items} loading={isLoading} />
    </HomeItemScoff>
  )
}

// HomeTags.propTypes = {
//   className: PropTypes.string,
// }
