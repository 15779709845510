/**
 * RecommendBody
 * 
 * @Author: Focci
 * @Date: 2023-08-30 08:22:14
 * @Last Modified by: Focci
 * @Last Modified time: 2023-08-30 08:22:14
 */

'use client'

import PropTypes from 'prop-types'
import Advert from '@page/common/ad'
import { isArray } from 'lodash-es'
import { useCallback, useMemo, useState } from 'react'

function AdvertItem({ data, width, height, showTitle = true }) {
  const [index, setIndex] = useState(0)
  const handleChange = useCallback(({ index: i }) => setIndex(i), [])
  const title = useMemo(() => data?.[index]?.title, [data, index])
  
  return (
    <div
      className="rounded-md overflow-hidden relative"
      style={{ aspectRatio: `${width} / ${height}` }}
    >
      <Advert
        fill
        subtile
        adData={data}
        width={width}
        height={height}
        onChange={handleChange}
      />
      {showTitle && title && (
        <div 
          className="
            absolute bg-primary text-white left-0 bottom-0 pointer-events-none
            rounded-bl-md rounded-tr-md px-2 py-0.5 line-clamp-1
          "
        >
          {title}
        </div>
      )}
    </div>
  )
}

export default function RecommendBody({
  data = {}
}) {
  return (
    <>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
        <div className="flex flex-col gap-4">
          <AdvertItem
            data={data['2006']} 
            width={660} 
            height={380}
          />
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <AdvertItem
              data={data['2007']} 
              width={320} 
              height={240}
            />
            <AdvertItem
              data={data['2008']} 
              width={320} 
              height={240}
            />
          </div>
        </div>
        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-4">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <AdvertItem
                data={data['2009']} 
                width={320} 
                height={240}
              />
              <AdvertItem
                data={data['2010']} 
                width={320} 
                height={240}
              />
            </div>
            <AdvertItem
              data={data['2011']} 
              width={660} 
              height={380}
            />
          </div>
        </div>
      </div>
      {isArray(data['2012']) && data['2012'].length > 0 && (
        <div className="mt-6 md:mt-12">
          <AdvertItem
            showTitle={false}
            data={data['2012']} 
            width={1344} 
            height={260}
          />
        </div>
      )}
    </>
  )
}

RecommendBody.propTypes = {
  data: PropTypes.object,
}
