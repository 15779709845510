/* eslint-disable quotes */
/* eslint-disable quote-props */
import { houseSearchType } from '@lib/config'

export const verticalConfig = {
  [houseSearchType.property]: { vertical: 'buy', vertical_type: 'buy', sale_type: 'buy' },
  [houseSearchType.newHomes]: { vertical: 'buy', vertical_type: 'buy', sale_type: 'buy' },
  [houseSearchType.sold]: { vertical: 'estimate', vertical_type: 'sold', sale_type: 'sold' },
  [houseSearchType.rent]: { vertical: 'rent', vertical_type: 'rent' },
  [houseSearchType.estimate]: { vertical: 'estimate', vertical_type: 'estimate', sale_type: 'estimate' },
  [houseSearchType.commercialForSale]: { vertical: 'commercial', vertical_type: 'commercial for sale', sale_type: 'commercial for sale' },
  [houseSearchType.commercialForSale]: { vertical: 'commercial', vertical_type: 'commercial for lease', sale_type: 'commercial for lease' },
  [houseSearchType.rural]: { vertical: 'rural', vertical_type: 'rural', sale_type: 'sale' },
}

export const listingConfig = {
  [houseSearchType.property]: { vertical: 'buy', vertical_type: 'buy', page_type: 'search' },
  [houseSearchType.newHomes]: { vertical: 'buy', vertical_type: 'buy', page_type: 'search' },
  [houseSearchType.sold]: { vertical: 'estimate', vertical_type: 'sold', page_type: 'search' },
  [houseSearchType.rent]: { vertical: 'rent', vertical_type: 'rent', page_type: 'search' },
  [houseSearchType.estimate]: { vertical: 'estimate', vertical_type: 'estimate', page_type: 'search' },
  [houseSearchType.development]: { vertical: 'estimate', vertical_type: 'for developers', page_type: 'search' },
  [houseSearchType.commercialForSale]: { vertical: 'commercial', vertical_type: 'commercial for sale', page_type: 'search' },
  [houseSearchType.commercialForSale]: { vertical: 'commercial', vertical_type: 'commercial for lease', page_type: 'search' },
  [houseSearchType.rural]: { vertical: 'rural', vertical_type: 'rural', page_type: 'search' },
}
