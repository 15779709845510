/**
 * 我认领的房源
 * 
 * @Author: Focci
 * @Date: 2023-08-21 09:46:40
 * @Last Modified by: Focci
 * @Last Modified time: 2023-08-21 09:46:40
 */

'use client'

import PropTypes from 'prop-types'
import ClaimedCard, { ClaimedCardSkeleton } from '@comp/page/common/card/user/ClaimedCard'
import useFetch from '@hook/useFetch'
import { isArray } from 'lodash-es'
import { useMemo } from 'react'
import HomeItemCarousel from '../HomeItemCarousel'
import ClaimDefaultCard from './ClaimDefaultCard'

function ClaimedCardItem({ data }) {
  if(data.claimedCardType === 'more') {
    return <ClaimDefaultCard />
  }
  if(data.claimedCardType === 'loading') {
    return <ClaimedCardSkeleton />
  }
  return <ClaimedCard data={data} />
}

export default function Claimed({
  title
}) {
  const { data, isLoading } = useFetch('claimed-list', { limit: 5 })
  const listings = useMemo(() => {
    if(isLoading) {
      return [
        { claimedCardType: 'loading' },
        { claimedCardType: 'loading' },
        { claimedCardType: 'loading' },
      ]
    }

    const result = isArray(data) ? data : []
    return [...result, { claimedCardType: 'more' }]
  }, [data, isLoading])
  
  return (
    <HomeItemCarousel
      title={title}
      data={listings}
      SlideRender={ClaimedCardItem}
    />
  )
}

Claimed.propTypes = {
  title: PropTypes.node,
}
