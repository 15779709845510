/**
 * ListBox
 * 
 * @Author: Focci
 * @Date: 2023-09-06 12:02:41
 * @Last Modified by: Focci
 * @Last Modified time: 2023-09-06 12:02:41
 */

import PropTypes from 'prop-types'
import CustomTab from './CustomTab'

export default function ListBox({ 
  children, 
  value, 
  hasData,
  onClean 
}) {
  return (
    <>
      <CustomTab value={value} hasData={hasData} onClean={onClean} />
      <div className="mt-4">{children}</div>
    </>
  )
}

ListBox.propTypes = {
  children: PropTypes.node,
  value: PropTypes.string,
  onClean: PropTypes.func,
}
