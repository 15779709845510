/**
 * 快速导航
 * 
 * @Author: Focci
 * @Date: 2023-08-21 09:48:31
 * @Last Modified by: Focci
 * @Last Modified time: 2023-08-21 09:48:31
 */

'use client'

// import PropTypes from 'prop-types'
// import useLazyComponent from '@hook/useLazyComponent'
// import { Waypoint } from 'react-waypoint'
// import { useCallback, useState } from 'react'
import HomeItemScoff from '../HomeItemScoff'
import NavBarBody from './NavBarBody'

export function NavBarSkeleton() {
  return (
    <HomeItemScoff>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-1">
        <div className="h-12 bg-cf0" />
        <div className="h-12 bg-cf0" />
        <div className="h-12 bg-cf0" />
        <div className="h-12 bg-cf0" />
      </div>
      <div className="mt-6 flex items-center gap-x-6 justify-start lg:justify-around overflow-hidden">
        {Array(8).fill('').map((_, index) => (
          <div className="flex flex-col gap-y-1 items-center" key={index}>
            <div className="w-12 h-8 relative rounded-full bg-cf0" />
            <span className="bg-cf0 rounded-md w-12"><span className="invisible">....</span></span>
          </div>
        ))}
      </div>
    </HomeItemScoff>
  )
}

export default function NavBar() {
  // const [enter, setEnter] = useState(false)
  
  // const { Wedget: NavBarBody, getWedget, ready } = useLazyComponent()
  // const handleEnter = useCallback(() => {
  //   setEnter(true)
  //   getWedget(() => import('./NavBarBody'))
  // }, [getWedget])

  // if(!enter || !ready) {
  //   return (
  //     <Waypoint onEnter={handleEnter}>
  //       <div>
  //         <NavBarSkeleton />
  //       </div>
  //     </Waypoint>
  //   )
  // }

  return <NavBarBody />
}
