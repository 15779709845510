import(/* webpackMode: "eager" */ "/app/components/AspectRatioCover.js");
;
import(/* webpackMode: "eager" */ "/app/components/material/index.js");
;
import(/* webpackMode: "eager" */ "/app/components/page/common/footer/FooterCollapseItem.js");
;
import(/* webpackMode: "eager" */ "/app/components/page/common/header/Auth.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/components/page/common/header/FilterSearchIcon.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/components/page/common/header/headbar-resposive/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["LocaleSwitcher","LangSwitcher"] */ "/app/components/page/common/header/location-locale/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/components/page/common/header/NavBar.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/components/page/common/lang/initial/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/components/page/common/login/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/components/page/home/carousel/CarouselBody.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/components/page/home/guess-you-like/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/components/page/home/nav-bar/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/components/page/home/recommend/RecommendBody.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/components/page/home/search-bar/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/components/page/home/search-recording/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/components/page/home/tags/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/components/page/home/today-feature/ForUSerModule.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/components/user-tracking/page-views/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/app/public/imgs/hg-dowload-app-qrcode-au.png");
;
import(/* webpackMode: "eager" */ "/app/public/imgs/hg-dowload-app-qrcode.png");
;
import(/* webpackMode: "eager" */ "/app/public/imgs/logo-au.svg");
;
import(/* webpackMode: "eager" */ "/app/public/imgs/logo-en.svg");
;
import(/* webpackMode: "eager" */ "/app/public/imgs/logo.svg");
