/**
 * Items Carousel
 * 
 * @Author: Focci
 * @Date: 2023-08-22 13:58:55
 * @Last Modified by: Focci
 * @Last Modified time: 2023-08-22 13:58:55
 */

'use client'

import PropTypes from 'prop-types'
import clsx from 'clsx'
import useApp from '@hook/useApp'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import Swiper, { Navigation } from '@comp/swiper'
import { useCallback, useMemo, useState } from 'react'
// import 'swiper/css/grid'
// import { Grid } from 'swiper/modules'

function getSlideRenderItem(Render) {
  return function SlideRender(args) {
    return (
      <div className="h-full w-[calc(75vw-20px)] md:w-full">
        {Render(args)}
      </div>
    )
  }
}

export default function HomeItemCarousel({
  title,
  bodyClassName = '',
  skeleton,
  ...carouselProps
}) {
  const { isMobile } = useApp()
  const [swiper, setSwiper] = useState(null)
  const navIconClassName = 'w-[1.75rem] h-[1.75rem] border border-solid border-ce6 text-meta rounded-full'
  const slideClassName = useMemo(() => (isMobile ? '!h-auto !w-auto' : '!h-auto'), [isMobile])
  
  const handleReady = useCallback((swp) => setSwiper(swp), [])
  const SlideRender = useMemo(
    () => getSlideRenderItem(carouselProps.SlideRender), 
    [carouselProps]
  )

  return (
    <div className="mt-4 md:mt-12">
      <div className="flex items-center justify-between overflow-hidden">
        <div className="text-base md:text-f1.4 font-medium overflow-auto">
          {title}
        </div>
        <div className="flex items-center gap-2 shrink-0 pl-4">
          <Navigation
            isPrev
            swiper={swiper} 
            className={navIconClassName}
          >
            <KeyboardArrowLeftIcon sx={{ fontSize: '1.1rem' }} />
          </Navigation>
          <Navigation
            swiper={swiper} 
            className={navIconClassName}
          >
            <KeyboardArrowRightIcon sx={{ fontSize: '1.1rem' }} />
          </Navigation>
        </div>
      </div>
      <div className={clsx(['mt-4', bodyClassName])}>
        {skeleton || <Swiper 
          onReady={handleReady}
          slidesPerView={isMobile ? 'auto' : 3}
          // slidesPerGroup={isMobile ? 1 : 3}
          spaceBetween={15}
          slideClassName={slideClassName}
          breakpoints={{}}
          // modules={[Grid]}
          // grid={isGrid ? { rows: 3, fill: 'columns' } : { rows: 1 }}
          {...carouselProps}
          SlideRender={SlideRender}
        />}
      </div>
    </div>
  )
}

HomeItemCarousel.propTypes = {
  title: PropTypes.node,
  bodyClassName: PropTypes.string,
  skeleton: PropTypes.node,
  isGrid: PropTypes.bool,
}
