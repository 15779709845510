/**
 * 搜索记录
 * 
 * @Author: Focci
 * @Date: 2023-08-21 09:50:21
 * @Last Modified by: Focci
 * @Last Modified time: 2023-08-21 09:50:21
 */

'use client'

// import PropTypes from 'prop-types'
import { useEffect } from 'react'
import useLazyComponent from '@hook/useLazyComponent'
import useLogin from '@hook/useLogin'

export default function SearchRecording() {
  const { isLogin } = useLogin()
  const { Wedget: SearchRecordingBody, getWedget } = useLazyComponent()

  useEffect(() => {
    isLogin && getWedget(() => import('./SearchRecordingBody'))
  }, [getWedget, isLogin])
 
  return isLogin && (
    <SearchRecordingBody />
  )
}

// SearchRecording.propTypes = {
//   className: PropTypes.string,
// }
