/**
 * SearchBarTab
 * 
 * @Author: Focci
 * @Date: 2023-08-25 14:45:18
 * @Last Modified by: Focci
 * @Last Modified time: 2023-08-25 14:45:18
 */

// import PropTypes from 'prop-types'
import Tab from '@comp/Tab'
import { houseSearchType } from '@lib/config'
import { useTranslations } from 'next-intl'
import { useMemo } from 'react'

export default function SearchBarTab(tabProps) {
  const tm = useTranslations('menu')
  const ts = useTranslations('suburb')
  const tss = useTranslations('school')

  const options = useMemo(() => [
    { label: tm('houseForSale'), value: houseSearchType.residential },
    { label: tm('newHouse'), value: houseSearchType.newHomes },
    { label: tm('estimate'), value: houseSearchType.estimate },
    { label: ts('recentlySold'), value: houseSearchType.sold },
    { label: tm('rental'), value: houseSearchType.rental },
    { label: tss('findASchool'), value: 'school' }
  ], [tm, ts, tss])

  return (
    <div className="min-w-full overflow-x-auto">
      <Tab 
        options={options}
        {...tabProps}
        wrapperClassName="gap-x-0"
        buttonClassName="rounded-t-md"
        activeClassName="bg-primary text-white"
        unActiveClassName="hover:bg-cf0"
        className="
          text-f.8 md:text-f.9
          px-2 md:px-3 lg:px-4 pt-2 md:pt-3 pb-2 
          rounded-t-md font-medium
        "
      />
    </div>
  )
}

// SearchBarTab.propTypes = {
//   className: PropTypes.string,
// }
