/**
 * GuessListingSkeleton
 * 
 * @Author: Focci
 * @Date: 2024-06-12 13:34:32
 * @Last Modified by: Focci
 * @Last Modified time: 2024-06-12 13:34:32
 */

// import PropTypes from 'prop-types'
import { Skeleton } from '@comp/material'

export default function GuessListingSkeleton() {
  return (
    <div className="flex flex-col">
      <Skeleton 
        variant="rectangular" 
        style={{ paddingTop: '75%', borderRadius: '.375rem' }} 
      />
      <div className="text-f.8 text-primary font-medium mt-2">
        <Skeleton variant="text" style={{ width: '50%' }} />
      </div>
      <div>
        <Skeleton variant="text" style={{ width: '70%' }} />
      </div>
    </div>
  )
}

// GuessListingSkeleton.propTypes = {
//   className: PropTypes.string,
// }
