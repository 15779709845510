/**
 * 猜你喜欢
 * 
 * [FE] 首页下方房屋推荐模块只保留猜你喜欢，附近、买房、租房模块去掉
 * https://7thave.atlassian.net/browse/HT-3299
 * 
 * @Author: Focci
 * @Date: 2023-08-21 09:48:00
 * @Last Modified by: Focci
 * @Last Modified time: 2023-08-21 09:48:00
 */

'use client'

// import PropTypes from 'prop-types'
// import { useState, useMemo } from 'react'
import { useTranslations } from 'next-intl'
// import Tab from '@comp/Tab'
import HomeItemScoff from '../HomeItemScoff'
import GuessListings from './GuessListings'

export default function HomeFavorite() {
  const ts = useTranslations('search')
  // const th = useTranslations('home')
  // const tm = useTranslations('menu')

  // const options = useMemo(() => [
  //   { label: ts('guessYouLike'), value: '猜您喜欢' },
  //   { label: th('nearBy'), value: '附近' },
  //   { label: tm('houseForSale'), value: '买房' },
  //   { label: tm('rental'), value: '租房' },
  // ], [ts])

  // const [tag, setTag] = useState(options[0].value)

  return (
    <HomeItemScoff
      title={ts('guessYouLike')}
      // title={
      //   <Tab
      //     className="text-f.9"
      //     activeClassName="text-primary"
      //     options={options}
      //     value={tag}
      //     activeSign={<span />}
      //     onChange={({ value }) => setTag(value)} 
      //   />
      // }
    >
      <GuessListings tag="猜您喜欢" />
    </HomeItemScoff>
  )
}

// GuessYouLike.propTypes = {
//   className: PropTypes.string,
// }
