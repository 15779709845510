/**
 * RecommendTags
 * 
 * @Author: Focci
 * @Date: 2024-06-11 14:41:29
 * @Last Modified by: Focci
 * @Last Modified time: 2024-06-11 14:41:29
 */

// import PropTypes from 'prop-types'
import Link from 'next/link'
import useTracking from '@hook/useTracking'
import { imageSize } from '@lib/image/loader'
import { isArray } from 'lodash-es'
import { useLocale, useTranslations } from 'next-intl'
import { houseSearchType, typeOfId } from '@lib/config'
import { useCallback, useMemo } from 'react'
import { getInitRecordOption } from '@hook/useSearchRecording'
import { args } from '@lib/parse'

function RecommendTagsSkeleton() {
  return (
    <div className="rounded-md aspect-[250/165] bg-cf0" />
  )
}

function RecommendTagsScoff({ children }) {
  return (
    <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4">
      {children}
    </div>
  )
}

function RecommendTag({ data }) {
  const locale = useLocale()
  const th = useTranslations('home')
  const ts = useTranslations('search')
  const { pushTrackSearch } = useTracking()

  const url = useMemo(() => {
    const { paramsMeta } = data || {}
    const searchType = typeOfId[paramsMeta?.typeId || houseSearchType.residential]
    return getInitRecordOption({ paramMeta: paramsMeta, searchType }, locale).url
  }, [data, locale])

  const handleClick = useCallback(() => {
    const { paramsMeta } = data || {}
    const type = typeOfId[paramsMeta?.typeId || houseSearchType.residential]
    const query = args.listingApi(type, paramsMeta)
    
    pushTrackSearch({ 
      ...paramsMeta,
      searchType: 'classifications',
      type 
    }, query)
  }, [data, pushTrackSearch])
  
  return (
    <Link
      href={url}
      onClick={handleClick}
      className="rounded-md aspect-[250/165] bg-cf0 bg-cover relative cursor-pointer"
      style={{ backgroundImage: `url(${imageSize(data.image, 600)})` }}
    >
      <div
        className="
          text-f.9 text-white font-bold p-2 absolute inset-0 rounded-md transition-all
          bg-gradient-to-b from-black/0 to-black/70 hover:from-black/10 hover:to-black/50
          group/recommend-tag
        "
      >
        <div 
          className="
            absolute left-1/2 -translate-x-1/2 bottom-3 transition text-center
            group-hover/recommend-tag:bottom-auto 
            group-hover/recommend-tag:top-1/2 
            group-hover/recommend-tag:-translate-y-1/2
          "
        >
          {`${data?.name} ${th('countOfProperty', { n: data?.count })}`}
          <div 
            className="
              text-center mt-2 -mb-8
              scale-50 opacity-0 transition-all
              group-hover/recommend-tag:scale-100
              group-hover/recommend-tag:opacity-100
              group-hover/recommend-tag:-mb-0
            "
          >
            <div 
              className="
                bg-white rounded-full text-c3b text-f.7 inline-flex mx-auto 
                px-3 h-6 items-center
              "
            >
              {ts('view')}
            </div>
          </div>
        </div>
      </div>
    </Link>
  )
}

export default function RecommendTags({
  data,
  loading,
}) {
  if(loading) {
    return (
      <RecommendTagsScoff>
        {Array(10).fill('').map((_, index) => (
          <RecommendTagsSkeleton key={index} />
        ))}
      </RecommendTagsScoff>
    )
  }

  return (
    <RecommendTagsScoff>
      {isArray(data) && data.map((it, index) => (
        <RecommendTag key={`${it.name}_${index}`} data={it} />
      ))}
    </RecommendTagsScoff>
  )
}

// RecommendTags.propTypes = {
//   className: PropTypes.string,
// }
