/**
 * ListingsHistoryCard
 * 
 * @Author: Focci
 * @Date: 2023-09-06 12:04:38
 * @Last Modified by: Focci
 * @Last Modified time: 2023-09-06 12:04:38
 */

import PropertyCard from '@page/common/card/property'
import { isFunction } from 'lodash-es'
import { useState, useCallback } from 'react'
import { ButtonBase, CircularProgress } from '@comp/material'
import { useTranslations } from 'next-intl'

export default function ListingsHistoryCard(props) {
  const { onRemove, house, recordingId, type } = props
  const tp = useTranslations('profile')
  const [loading, setLoading] = useState(false)

  // 删除
  const handleRemove = useCallback(() => {
    !loading && isFunction(onRemove) && onRemove({ recordingId, setLoading })
  }, [loading, onRemove, recordingId])
  
  return (
    <div className="flex flex-col">
      <PropertyCard
        slideChangeTrackingPageType="user_profile_view_history"
        house={house} 
        type={type} 
      />
      <div className="border rounded-b-md -mt-1 bg-white">
        <ButtonBase 
          className="w-full rounded-b-md"
          onClick={handleRemove}
        >
          <span className="p-1 text-primary">
            {loading ? <CircularProgress size="0.7rem" /> : tp('removeRecording')}
          </span>
        </ButtonBase>
      </div>
    </div>
  )
}
