/**
 * CarouselBody
 * 
 * @Author: Focci
 * @Date: 2023-11-12 15:55:13
 * @Last Modified by: Focci
 * @Last Modified time: 2023-11-12 15:55:13
 */

'use client'

import PropTypes from 'prop-types'
import Advert from '@comp/page/common/ad'
import useApp from '@hook/useApp'

export default function CarouselBody({
  data = []
}) {
  const { isMobile } = useApp()

  return (
    <div className="h-[10rem] md:h-[15rem] overflow-hidden">
      <Advert
        fill
        subtile
        width={1920}
        height={isMobile ? 200 : 300}
        adData={data}
        style={{ height: isMobile ? '10rem' : '15rem' }}
      />
    </div>
  )
}

CarouselBody.propTypes = {
  data: PropTypes.array,
}
