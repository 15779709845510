/**
 * 新用户首次设置语言 / 国家
 * 
 * @Author: Focci
 * @Date: 2024-02-22 09:45:32
 * @Last Modified by: Focci
 * @Last Modified time: 2024-02-22 09:45:32
 */

'use client'

import useLazyComponent from '@hook/useLazyComponent'
import { useEffect } from 'react'
import { cookieGet, isWindow } from '@lib/utils'
import { localCookieName } from '@i18n/config'
import { HG_CURRENT_COUNTRY_NAME } from '@lib/config'

export default function InitialSetting() {
  const { Wedget: InitialSettingBody, getWedget } = useLazyComponent()

  useEffect(() => {
    if(isWindow) {
      const lang = cookieGet(localCookieName)
      const locale = cookieGet(HG_CURRENT_COUNTRY_NAME)

      if(!lang || !locale) {
        getWedget(() => import('./InitialSettingBody'))
      }
    }
  }, [getWedget])

  return (
    <InitialSettingBody />
  )
}
