/**
 * FavoriteDefaultCard
 * 
 * @Author: Focci
 * @Date: 2023-08-22 15:28:41
 * @Last Modified by: Focci
 * @Last Modified time: 2023-08-22 15:28:41
 */

// import PropTypes from 'prop-types'
import SVGClaim from '@img/claim.svg'
import { houseSearchType } from '@lib/config'
import { urls } from '@lib/parse'
import { useLocale, useTranslations } from 'next-intl'
import DefaultCard from '../DefaultCard'

export default function FavoriteDefaultCard() {
  const locale = useLocale()
  const th = useTranslations('home')

  return (
    <DefaultCard 
      icon={<SVGClaim />}
      label={th('bookmarkListings')}
      meta={th('bookmarkListingNote')}
      url={urls.listingDefault(houseSearchType.residential, locale)}
      actionLabel={th('searchHouseInterestedIn')}
    />
  )
}

// FavoriteDefaultCard.propTypes = {
//   className: PropTypes.string,
// }
