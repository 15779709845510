/**
 * DefaultCard
 * 
 * @Author: Focci
 * @Date: 2023-08-22 10:28:48
 * @Last Modified by: Focci
 * @Last Modified time: 2023-08-22 10:28:48
 */

import { ButtonBase } from '@comp/material'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import Link from 'next/link'
import PropTypes from 'prop-types'

export default function DefaultCard({
  icon,
  label = '',
  meta = '',
  actionLabel = '',
  url = '',
}) {
  return (
    <div 
      className="
        flex items-center justify-center border rounded-md px-4 py-12
        h-full box-border
      "
    >
      <div className="flex flex-col items-center">
        {icon}
        <b className="text-f.9 mt-4">{label}</b>
        <span className="text-meta mb-4">{meta}</span>
        <ButtonBase className="rounded-md">
          <Link 
            href={url}
            className="
              border border-primary rounded-md flex items-center h-8 px-4
              text-primary gap-x-1
            "
          >
            {actionLabel}
            <ArrowForwardIosIcon sx={{ fontSize: '.7rem' }} />
          </Link>
        </ButtonBase>
      </div>
    </div>
  )
}

DefaultCard.propTypes = {
  icon: PropTypes.node,
  label: PropTypes.string,
  meta: PropTypes.string,
  actionLabel: PropTypes.string,
  url: PropTypes.string,
}
