/**
 * 需要用户登录后才显示的模块
 * 
 * @Author: Focci
 * @Date: 2023-08-21 10:22:43
 * @Last Modified by: Focci
 * @Last Modified time: 2023-08-21 10:22:43
 */

'use client'

import useLogin from '@hook/useLogin'
import RecommendedHousesToday from '@page/home/recommended-houses-today'
import BrowsingHistory from '@page/home/browsing-history'
import Favorite from '@page/home/favorite'
import Claimed from '@page/home/claimed'
import { useCallback, useMemo, useState } from 'react'
import { filter, map } from 'lodash-es'
import { useTranslations } from 'use-intl'
import { isAU } from '@lib/utils'
import BusinessIntro from '../search-recording/BusinessIntro'
import TabCircular from './TabCircular'
import HomeItemScoff from '../HomeItemScoff'

export default function ForUSerModule({
  todayFeature = []
}) {
  const tc = useTranslations('common')
  const ts = useTranslations('search')
  const tt = useTranslations('track')

  const { isLogin } = useLogin()
  const [tabValue, setTabValue] = useState('feature')
  const handleChange = useCallback(({ value: v }) => setTabValue(v), [])

  const tabOptions = useMemo(() => {
    const tmp = []
    tmp.push(
      { label: ts('recommend'), value: 'feature', component: RecommendedHousesToday },
      { label: ts('justWatched'), value: 'viewed', component: BrowsingHistory },
      { label: tc('collectioned'), value: 'favorite', component: Favorite },
    )

    if(!isAU) {
      tmp.push({ label: tt('claimed'), value: 'claimed', component: Claimed })
    }

    return tmp
  }, [ts, tc, tt])

  const current = useMemo(
    () => filter(tabOptions, (f) => f.value === tabValue)[0], 
    [tabOptions, tabValue]
  )

  const CurrentComponent = useMemo(() => current.component, [current])
  const currentValue = useMemo(() => current.value, [current])
  const needLoginTab = useMemo(
    () => map(filter(tabOptions, (f) => ['feature', 'viewed'].indexOf(f.value) === -1), 'value'), 
    [tabOptions]
  )

  if(!isLogin && needLoginTab.indexOf(tabValue) > -1) {
    return (
      <HomeItemScoff
        title={
          <TabCircular
            options={tabOptions} 
            value={currentValue}
            onChange={handleChange} 
          />
        }
      >
        <BusinessIntro />
      </HomeItemScoff>
    )
  }
  
  return (
    <CurrentComponent 
      {...(tabValue === 'feature' ? { data: todayFeature } : null)}
      onChange={handleChange}
      title={(
        <TabCircular
          options={tabOptions} 
          value={currentValue}
          onChange={handleChange} 
        />
      )} 
    />
  )
}
