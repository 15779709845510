/**
 * GuessListings
 * 
 * @Author: Focci
 * @Date: 2023-08-21 14:29:15
 * @Last Modified by: Focci
 * @Last Modified time: 2023-08-21 14:29:15
 */

import PropTypes from 'prop-types'
import useFetch from '@hook/useFetch'
import NoData from '@comp/NoData'
import useLazyComponent from '@hook/useLazyComponent'
import UserTracking from '@comp/user-tracking'
import { isArray, map, times } from 'lodash-es'
import { useCallback, useMemo, useState } from 'react'
import { Waypoint } from 'react-waypoint'
import { houseToGta } from '@lib/utils'
import GuessListingSkeleton from './GuessListingSkeleton'
import { ShowInHomePageTracking } from '../recommended-houses-today'

export function GuessListingsScoff({
  children
}) {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
      {children}
    </div>
  )
}

export default function GuessListings({
  tag = ''
}) {
  const { Wedget: GuessListing, getWedget } = useLazyComponent()
  const [enter, setEnter] = useState(false)

  const { data, isLoading } = useFetch(
    enter ? 'waterfall-listings' : null, 
    { tag, typeId: 0, limit: 16 }
  )

  const listings = useMemo(() => (
    isArray(data) 
      ? data.map((item) => ({ ...item[item.data_type], isAd: item.data_type === 'ad' }))
      : [] 
  ), [data])
  
  const handleEnter = useCallback(() => {
    setEnter(true)
    getWedget(() => import('./GuessListing'))
  }, [getWedget])

  if(!enter || isLoading) {
    return (
      <Waypoint onEnter={handleEnter}>
        <div>
          <GuessListingsScoff>
            {times(16, String).map((_, index) => (
              <GuessListingSkeleton key={index} />
            ))}
          </GuessListingsScoff>
        </div>
      </Waypoint>
    )
  }

  if(!data || (isArray(data) && data.length === 0)) {
    return (
      <div className="text-center bg-cf0 py-8">
        <NoData />
      </div>
    )
  }
  
  return (
    <GuessListingsScoff>
      {listings.map((item) => (
        <GuessListing
          key={item.id}
          data={item}
          isAd={item.isAd}
        />
      ))}
      <UserTracking
        auOnly
        once
        eventName="homely_card_view"
        params={houseToGta(map(data, 'house'), 'home', 'search_result')}
      />
      <UserTracking
        auOnly
        once
        eventName="homely_photo_view"
        params={houseToGta(map(data, 'house'), 'home', 'search_result')}
      />
      <ShowInHomePageTracking data={listings} />
    </GuessListingsScoff>
  )
}

GuessListings.propTypes = {
  tag: PropTypes.string,
}
