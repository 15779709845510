/**
 * CustomTab
 * 
 * @Author: Focci
 * @Date: 2023-09-06 11:29:02
 * @Last Modified by: Focci
 * @Last Modified time: 2023-09-06 11:29:02
 */

import Tab from '@comp/Tab'
import useProgressRouter from '@hook/useProgressRouter'
import { browsingHistory } from '@lib/route/profile'
import { Button, CircularProgress } from '@comp/material'
import { isFunction } from 'lodash-es'
import { useLocale, useTranslations } from 'next-intl'
import { useCallback, useMemo, useState } from 'react'

export default function CustomTab({ 
  value,
  hasData = false,
  onClean 
}) {
  const locale = useLocale()
  const ts = useTranslations('search')
  const tm = useTranslations('menu')
  const tc = useTranslations('common')

  const [loading, setLoading] = useState(false)
  const { routerPush } = useProgressRouter()

  const options = useMemo(() => [
    { label: ts('property'), value: 'listings' },
    { label: tm('information'), value: 'news' },
  ], [ts, tm])

  // Tab change
  const handleChange = useCallback((d) => {
    if(value !== d.value) {
      routerPush(browsingHistory(d.value, locale))
    }
  }, [routerPush, value, locale])

  // 清空
  const handleRemove = useCallback(() => {
    !loading && isFunction(onClean) 
      && onClean({ recordingId: 'all', setLoading })
  }, [loading, onClean])

  return (
    <div className="flex items-center justify-between gap-2">
      <Tab 
        options={options}
        className="border-b border-b-2 pb-1 text-base"
        unActiveClassName="border-transparent"
        activeClassName="border-primary text-primary"
        activeSign={<span />}
        value={value}
        onChange={handleChange}
      />
      {hasData && (
        <Button 
          color="info" 
          variant="outlined" 
          size="small"
          onClick={handleRemove}
        >
          {loading 
            ? <span><CircularProgress size="0.7rem" /></span>
            : tc('clean')}
        </Button>
      )}
    </div>
  )
}
