/**
 * TabCircular
 * 
 * @Author: Focci
 * @Date: 2023-11-29 14:41:00
 * @Last Modified by: Focci
 * @Last Modified time: 2023-11-29 14:41:00
 */

import PropTypes from 'prop-types'
import Tab from '@comp/Tab'

export default function TabCircular({
  options,
  ...props
}) {
  return (
    <Tab 
      options={options}
      wrapperClassName="gap-x-2"
      className="px-5 h-10 text-f.8 font-medium rounded-full flex items-center bg-cf8 hover:bg-[#F3F1F2]"
      buttonClassName="rounded-full"
      activeSign={<span />}
      activeClassName="border border-[#3B4450]"
      {...props}
    />
  )
}

TabCircular.propTypes = {
  options: PropTypes.array,
}
