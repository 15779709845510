/**
 * 今日推荐房源
 * 
 * @Author: Focci
 * @Date: 2023-08-21 09:49:33
 * @Last Modified by: Focci
 * @Last Modified time: 2023-08-21 09:49:33
 */

import PropTypes from 'prop-types'
import UserTracking from '@comp/user-tracking'
import PropertyCard from '@comp/page/common/card/property'
import useApp from '@hook/useApp'
import { useMemo } from 'react'
import { isArray, map } from 'lodash-es'
import { getListingData } from '@comp/page/search/get-listing-data'
import { houseToGta } from '@lib/utils'
import HomeItemCarousel from '../HomeItemCarousel'

export function ShowInHomePageTracking({ data }) {
  const trackParams = useMemo(() => (
    map(isArray(data) ? data : [], (item) => ({ 
      houseId: item?.id || 0, 
      suburbId: item?.suburbId || 0, 
      animation: item?.animation,
      agentId: isArray(item?.simple_agents) ? map(item.simple_agents, 'id').join(',') : '',
      agencyOfficeId: item?.office_id,
    }))
  ), [data])

  return (
    <UserTracking
      once
      eventName="show_in_home_page"
      params={trackParams}
    />
  )
}

function ListingCard({ data }) {
  return (
    <PropertyCard
      house={data}
      cardWrapperClassName="h-full box-border border rounded-md overflow-hidden"
      slideChangeTrackingPageType="home"
      agentAvatarProps={{ className: 'w-12 md:w-16 -mt-4 md:-mt-6' }}
    />
  )
}

export default function RecommendHouses({
  title,
  data = []
}) {
  const { isMobile } = useApp()
  
  const listings = useMemo(() => map(
    isArray(data) ? data : [], 
    (item) => {
      const tmp = { ...getListingData(item), animation: false }
      if(isMobile) tmp.isFeature = false
      return tmp
    }
  ), [data, isMobile])
  
  return (
    <>
      <HomeItemCarousel
        title={title}
        data={listings}
        SlideRender={ListingCard}
      />
      <UserTracking
        auOnly
        once
        eventName="homely_card_view"
        params={houseToGta(map(listings, 'house'), 'home', 'search_result')}
      />
      <UserTracking
        auOnly
        once
        eventName="homely_photo_view"
        params={houseToGta(map(listings, 'house'), 'home', 'search_result')}
      />
      <ShowInHomePageTracking data={listings} />
    </>
  )
}

RecommendHouses.propTypes = {
  title: PropTypes.node,
  data: PropTypes.array,
}
