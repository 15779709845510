/**
 * Home Item Scoff
 * 
 * @Author: Focci
 * @Date: 2023-08-21 09:51:54
 * @Last Modified by: Focci
 * @Last Modified time: 2023-08-21 09:51:54
 */

import PropTypes from 'prop-types'

export default function HomeItemScoff({
  title,
  children
}) {
  return (
    <div className="mt-6 md:mt-12">
      {title && (
        <div className="text-base md:text-f1.4 font-medium mb-4">
          {title}
        </div>
      )}
      <div>
        {children}
      </div>
    </div>
  )
}

HomeItemScoff.propTypes = {
  title: PropTypes.node,
  children: PropTypes.node,
}
