/**
 * ClaimDefaultCard
 * 
 * @Author: Focci
 * @Date: 2023-08-22 10:32:48
 * @Last Modified by: Focci
 * @Last Modified time: 2023-08-22 10:32:48
 */

// import PropTypes from 'prop-types'
import SVGClaim from '@img/claim.svg'
import { houseSearchType } from '@lib/config'
import { urls } from '@lib/parse'
import { useLocale, useTranslations } from 'next-intl'
import DefaultCard from '../DefaultCard'

export default function ClaimDefaultCard() {
  const th = useTranslations('house')
  const locale = useLocale()

  return (
    <DefaultCard 
      icon={<SVGClaim />}
      label={th('claimProperty')}
      meta={th('timelyAccessGovInfoOfProperty')}
      url={urls.listingDefault(houseSearchType.estimate, locale)}
      actionLabel={th('claimProperty')}
    />
  )
}

// ClaimDefaultCard.propTypes = {
//   className: PropTypes.string,
// }
