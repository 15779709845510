/**
 * https://docs.google.com/spreadsheets/d/11j8YtTD1EEs_baPqIBvQDkxwyJ-HSS_qTyYHKCUh1OY/edit#gid=1901314994
 */

import Cookies from 'js-cookie'
// import { filterConfig, filterMeta } from '@comp/page/common/filter/combine/filter-modal/meta'
import { modeType } from '@lib/parse'
import { UUID_PROP, houseSearchType } from '@lib/config'
// import { formatPrice } from '@lib/utils'
import { formatDate, isWithinMonth } from '@lib/datetime'
import { isArray, map, forEach } from 'lodash-es'
import { verticalConfig, listingConfig } from './config'

/**
 * 首页
 */
function getHomepageParam() {
  return {
    vertical: 'hougarden', 
    vertical_type: 'homepage', 
    page_type: 'homepage' 
  }
}

const getValue = (z) => map(isArray(z) ? z : [], 'label').join(',')
const getArrVal = (z) => (isArray(z) ? z : []).join(',')
const getPrice = (p) => {
  if(!p || p === 'any') return ''
  // if(/\d+/.test(p)) return parseFloat(p)
  return p
}
// const getFilterMeta = (filterName, cfg, data) => {
//   const tmp = []

//   if(isArray(data) && data.length > 0) {
//     const alias = cfg?.alias?.[filterName] || filterName
//     const meta = filterMeta[alias]
//     const itemValue = filter(data, (f) => f.label === filterName)[0]?.value

//     if(isValid(itemValue) && meta) {
//       const vals = itemValue.split(',')
//       const labels = filter(
//         isArray(meta?.items) ? meta.items : [],
//         (f) => vals.indexOf(f.value) > -1
//       )

//       labels.length > 0 && tmp.push(...map(labels, 'label'))
//     }
//   }
  
//   return tmp.join(',')
// }

/**
 * 房源列表
 */
export function getListingParam(data) {
  const tmp = { ...listingConfig[data.type] }

  // const filterCfg = filterConfig[data.type]
  const [pfrom, pto] = isArray(data.price) ? data.price : []
  const school = data?.school?.label || ''
  const street = data?.street?.label || ''
  const priceFrom = getPrice(pfrom)
  const priceTo = getPrice(pto)

  let propertyTypeStr = ''
  let viewType = ''
  
  // 房源类型
  forEach(modeType, (k) => {
    if(isArray(data[k])) {
      propertyTypeStr = data[k]?.join(',')
      viewType = k
    }
  })

  tmp.region_name = getValue(data.region)
  tmp.district_name = getValue(data.district)
  tmp.suburb_name = getValue(data.suburb)
  tmp.street_name = street
  tmp.sort_by = data.order || ''
  tmp.view_type = viewType === modeType.listing ? 'multiple' : viewType
  
  if([
    houseSearchType.residential,
    houseSearchType.newHomes,
  ].indexOf(data.type) > -1) {
    tmp.school_name = school
    tmp.bedrooms = getArrVal(data.bedroom)
    tmp.bathrooms = getArrVal(data.bathroom)
    tmp.price_from = priceFrom
    tmp.price_to = priceTo
    tmp.property_types = propertyTypeStr
    // tmp.price_method = getFilterMeta('price-method', filterCfg, data.filters)
    // tmp.listed_time = getFilterMeta('listed', filterCfg, data.filters)
    // tmp.land_area = getFilterMeta('land-area', filterCfg, data.filters)
    // tmp.floor_area = getFilterMeta('floor-area', filterCfg, data.filters)
    // tmp.year_build = getFilterMeta('decade-built', filterCfg, data.filters)
    // tmp.title_type = getFilterMeta('title-types', filterCfg, data.filters)
    // tmp.cv_range = getFilterMeta('rating-valuation', filterCfg, data.filters)
    // tmp.zoning_type = getFilterMeta('unitary-plan', filterCfg, data.filters)
    // tmp.school_deciles = getFilterMeta('school-zone', filterCfg, data.filters)
  } else if(data.type === houseSearchType.sold) {
    tmp.school_name = school
    tmp.bedrooms = getArrVal(data.bedroom)
    tmp.bathrooms = getArrVal(data.bathroom)
    tmp.price_from = priceFrom
    tmp.price_to = priceTo
    // tmp.sold_time = getFilterMeta('st', filterCfg, data.filters)
    // tmp.land_area = getFilterMeta('land-area', filterCfg, data.filters)
    // tmp.floor_area = getFilterMeta('floor-area', filterCfg, data.filters)
    // tmp.year_build = getFilterMeta('decade-built', filterCfg, data.filters)
    // tmp.title_type = getFilterMeta('title-types', filterCfg, data.filters)
    // tmp.cv_range = getFilterMeta('rating-valuation', filterCfg, data.filters)
  } else if(data.type === houseSearchType.rental) {
    tmp.school_name = school
    tmp.bedrooms = getArrVal(data.bedroom)
    tmp.bathrooms = getArrVal(data.bathroom)
    tmp.price_from = priceFrom
    tmp.price_to = priceTo
    tmp.property_types = propertyTypeStr
    // tmp.listed_time = getFilterMeta('listed', filterCfg, data.filters)
    // tmp.land_area = getFilterMeta('land-area', filterCfg, data.filters)
    // tmp.floor_area = getFilterMeta('floor-area', filterCfg, data.filters)
    // tmp.year_build = getFilterMeta('decade-built', filterCfg, data.filters)
    // tmp.title_type = getFilterMeta('title-types', filterCfg, data.filters)
    // tmp.cv_range = getFilterMeta('rating-valuation', filterCfg, data.filters)
    // tmp.school_deciles = getFilterMeta('school-zone', filterCfg, data.filters)
  } else if([
    houseSearchType.estimate,
    houseSearchType.development,
  ].indexOf(data.type) > -1) {
    tmp.school_name = school
    // tmp.consent_type = getFilterMeta('ct', filterCfg, data.filters)
    // tmp.consent_price = getFilterMeta('cv', filterCfg, data.filters)
    // tmp.cv_from = priceFrom
    // tmp.cv_to = priceTo
    // tmp.land_area = getFilterMeta('land-area', filterCfg, data.filters)
    // tmp.floor_area = getFilterMeta('floor-area', filterCfg, data.filters)
    // tmp.year_build = getFilterMeta('decade-built', filterCfg, data.filters)
    // tmp.title_type = getFilterMeta('title-types', filterCfg, data.filters)
  } else if(data.type === houseSearchType.commercialForSale) {
    tmp.property_types = propertyTypeStr
    tmp.price_from = priceFrom
    tmp.price_to = priceTo
    // tmp.price_method = getFilterMeta('price-method', filterCfg, data.filters)
    // tmp.listed_time = getFilterMeta('listed', filterCfg, data.filters)
    // tmp.land_area = getFilterMeta('land-area', filterCfg, data.filters)
    // tmp.floor_area = getFilterMeta('floor-area', filterCfg, data.filters)
    // tmp.year_build = getFilterMeta('decade-built', filterCfg, data.filters)
  } else if(data.type === houseSearchType.commercialForLease) {
    tmp.property_types = propertyTypeStr
    tmp.price_from = priceFrom
    tmp.price_to = priceTo
    // tmp.price_method = getFilterMeta('price-method', filterCfg, data.filters)
    // tmp.listed_time = getFilterMeta('listed', filterCfg, data.filters)
    // tmp.land_area = getFilterMeta('land-area', filterCfg, data.filters)
    // tmp.floor_area = getFilterMeta('floor-area', filterCfg, data.filters)
  } else if(data.type === houseSearchType.rural) {
    tmp.school_name = school
    tmp.property_types = propertyTypeStr
    tmp.price_from = priceFrom
    tmp.price_to = priceTo
    // tmp.price_method = getFilterMeta('price-method', filterCfg, data.filters)
    // tmp.listed_time = getFilterMeta('listed', filterCfg, data.filters)
    // tmp.land_area = getFilterMeta('land-area', filterCfg, data.filters)
    // tmp.floor_area = getFilterMeta('floor-area', filterCfg, data.filters)
    // tmp.year_build = getFilterMeta('decade-built', filterCfg, data.filters)
  }
  
  return tmp
}

/**
 * 中介列表
 */
function getAgentsParam({ searchParam, apiParam, isSingle }) {
  const tmp = {
    vertical: 'find an agent',
    vertical_type: apiParam?.typeId === '3' ? 'property manager' : 'residential agent',
    page_type: 'search',
    suburb_name: searchParam?.suburb?.[0]?.label || '',
    view_type: isSingle ? 'single' : 'multiple'
  }

  return tmp
}

/**
 * 中介详情
 */
function getAgentDetailParam(data) {
  const tmp = {
    vertical: 'find an agent',
    vertical_type: 'residential agent',
    page_type: 'details',
    agent_type: 'residential agent',
    agent_name: data.name,
    office_name: data.office_name,
    agency_brand: '',
    agent_id: data.id,
    is_feature_agent: data.is_vip
  }

  return tmp
}

/**
 * 房源详情
 */
function getListingDetailParam(data) {
  const isSoldOrEstimate = [
    houseSearchType.sold,
    houseSearchType.estimate,
  ].indexOf(data.type) > -1
  
  const tmp = {
    ...verticalConfig[data.type],
    page_type: 'listing',
    region_name: data.regionName,
    district_name: data.districtName,
    suburb_name: data.suburbName,
    address: isSoldOrEstimate ? data.h1 : data.address,
    bedrooms: data.bedrooms,
    bathrooms: data.bathrooms,
    property_id: data.propertyId || '',
    slug_id: data?.uid,
  }
  
  if([
    houseSearchType.property,
    houseSearchType.newHomes,
    houseSearchType.rent,
    houseSearchType.commercialForSale,
    houseSearchType.commercialForLease,
    houseSearchType.rural,
  ].indexOf(data.type) > -1) {
    // tmp.search_price = data.price ? formatPrice(data.price) : ''
    // tmp.price_method = data.priceMethod?.eng
    tmp.office_name = data?.office?.name
    tmp.agency_brand = data?.office?.brand
    tmp.property_types = data.categoryName || ''
    tmp.listing_id = data.houseId
    // tmp.is_newbuild = data.newHomes

    // 
    tmp.vertical_type = data.newHomes ? 'new home' : 'buy'
  }
  
  // 最近售出，则标记为售出
  const isSold = isSoldOrEstimate && data.soldInfo?.sold_date 
    ? isWithinMonth(data.soldInfo?.sold_date) 
    : false
  
  if(isSold) {
    tmp.sold_price = data.soldInfo?.sold_price
    tmp.sold_date = formatDate(data.soldInfo?.sold_date)
    tmp.property_types = data.categoryName || ''
    tmp.vertical_type = 'sold'
  }

  return tmp
}

/**
 * 新闻列表
 */
function getNews() {
  const tmp = {
    vertical: 'contents',
    vertical_type: 'news',
    page_type: 'index',
  }

  return tmp
}

/**
 * 新闻详情
 */
function getNewsDetail(data) {
  const tmp = {
    vertical: 'contents',
    vertical_type: 'news',
    page_type: 'details',
    content_slug: data.slug,
    content_title: data.subject,
    author_name: data.author,
    news_id: data.id,
  }

  return tmp
}

/**
 * 新闻直播列表
 */
function getNewsLives() {
  const tmp = {
    vertical: 'contents',
    vertical_type: 'live',
    page_type: 'index',
  }

  return tmp
}

/**
 * 新闻直播详情
 */
function getNewsLiveDetail(data) {
  const tmp = {
    vertical: 'contents',
    vertical_type: 'live',
    page_type: 'details',
    content_slug: data.slug,
    content_title: data.name,
    author_name: data.owner?.nickname,
    live_id: data.id,
  }

  return tmp
}

/**
 * 新闻知道列表
 */
function getNewsKnowledges() {
  const tmp = {
    vertical: 'contents',
    vertical_type: 'knowledges',
    page_type: 'index',
  }

  return tmp
}

/**
 * 新闻知道详情
 */
function getNewsKnowledgeDetail(data) {
  const tmp = {
    vertical: 'contents',
    vertical_type: 'knowledge',
    page_type: 'details',
    content_slug: data.slug,
    content_title: data.subject,
    author_name: data.author?.name,
    knowledge_id: data.id,
  }

  return tmp
}

/**
 * 新闻FM列表
 */
function getNewsFMs() {
  const tmp = {
    vertical: 'contents',
    vertical_type: 'fm',
    page_type: 'index',
  }

  return tmp
}

/**
 * 新闻FM详情
 */
function getNewsFMDetail(data) {
  const tmp = {
    vertical: 'contents',
    vertical_type: 'fm',
    page_type: 'details',
    fm_id: data.id,
    content_slug: '',
  }

  return tmp
}

/**
 * 新闻Topic列表
 */
function getNewsTopics() {
  const tmp = {
    vertical: 'contents',
    vertical_type: 'topics',
    page_type: 'index',
  }

  return tmp
}

/**
 * 新闻Topic详情
 */
function getNewsTopicDetail(data) {
  const tmp = {
    vertical: 'contents',
    vertical_type: 'topics',
    page_type: 'details',
    content_slug: data.slug,
    content_title: data.subject,
    topic_id: data.id,
    author_name: '',
  }

  return tmp
}

/**
 * 建筑汇产品列表
 */
function getSpecProducts(data) {
  const tag = isArray(data?.tag) ? data.tag[0] : {} 
  const companies = isArray(data?.company) ? data.company : []
  const companyName = map(companies, 'label').join(',')
  const companyId = map(companies, 'value').join(',')

  const tmp = {
    vertical: 'product_spec',
    vertical_type: 'product',
    page_type: 'search',
    tag_name: tag?.label || '',
    tag_id: tag?.value || '',
    supplier_id: companyId,
    supplier_name: companyName,
  }

  return tmp
}

/**
 * 建筑汇产品详情
 */
function getSpecProductDetail(data) {
  const tmp = {
    vertical: 'product_spec',
    vertical_type: 'product',
    page_type: 'details',
    product_name: data.title,
    product_id: data.id,
    supplier_id: data?.company?.id || '',
    supplier_name: data?.company?.name || '',
  }

  return tmp
}

/**
 * 建筑汇Company详情
 */
function getSpecCompanyDetail(data) {
  const tmp = {
    vertical: 'product_spec',
    vertical_type: 'company',
    page_type: 'details',
    supplier_id: data.id,
    supplier_name: data.name,
  }

  return tmp
}

/**
 * 建筑汇Case详情
 */
function getSpecCaseDetail(data) {
  const tmp = {
    vertical: 'product_spec',
    vertical_type: 'case study',
    page_type: 'details',
    case_study_name: data.title,
    company_name: data.provider?.name || '',
    company_id: data.provider?.id || '',
  }

  return tmp
}

/**
 * 区域分析列表
 */
function getSuburbs(data) {
  const tmp = {
    vertical: 'suburb',
    vertical_type: 'suburb',
    page_type: 'search',
    sort_by: data.order || ''
  }

  return tmp
}

/**
 * 区域分析详情
 */
function getSuburbDetail(data) {
  const tmp = {
    vertical: 'suburb',
    vertical_type: 'suburb',
    page_type: 'index',
    suburb_name: data.suburb_name
  }

  return tmp
}

export function getPageViewsParam(type, data, user) {
  let param
  switch(type) {
    case 'homepage':
      param = getHomepageParam()
      break
    case 'detail':
      param = getListingDetailParam(data)
      break
    case 'listing':
      param = getListingParam(data)
      break
    case 'agents':
      param = getAgentsParam(data)
      break
    case 'agentDetail':
      param = getAgentDetailParam(data)
      break
    case 'news':
      param = getNews(data)
      break
    case 'newsDetail':
      param = getNewsDetail(data)
      break
    case 'newsLives':
      param = getNewsLives(data)
      break
    case 'newsLiveDetail':
      param = getNewsLiveDetail(data)
      break
    case 'newsKnowledges':
      param = getNewsKnowledges(data)
      break
    case 'newsKnowledgeDetail':
      param = getNewsKnowledgeDetail(data)
      break
    case 'newsFMs':
      param = getNewsFMs(data)
      break
    case 'newsFMDetail':
      param = getNewsFMDetail(data)
      break
    case 'newsTopics':
      param = getNewsTopics(data)
      break
    case 'newsTopicDetail':
      param = getNewsTopicDetail(data)
      break
    case 'specProducts':
      param = getSpecProducts(data)
      break
    case 'specProductDetail':
      param = getSpecProductDetail(data)
      break
    case 'specCompanyDetail':
      param = getSpecCompanyDetail(data)
      break
    case 'specCaseDetail':
      param = getSpecCaseDetail(data)
      break
    case 'suburbs':
      param = getSuburbs(data)
      break
    case 'suburbDetail':
      param = getSuburbDetail(data)
      break
    default:
      param = {}
  }
  
  return {
    event: 'page_views',
    is_logged_in: !!user,
    user_id: user?.id || '',
    uuid: user?.uuid || Cookies.get(UUID_PROP) || '',
    country: String(process.env.NEXT_PUBLIC_COUNTRY).toLowerCase(),
    ...param
  }
}
