/**
 * 我看过的房子
 * 
 * @Author: Focci
 * @Date: 2023-08-21 09:47:37
 * @Last Modified by: Focci
 * @Last Modified time: 2023-08-21 09:47:37
 */

import PropTypes from 'prop-types'
import useFetch from '@hook/useFetch'
import NoData from '@comp/NoData'
import PropertyCard from '@page/common/card/property'
import useApp from '@hook/useApp'
import UserTracking from '@comp/user-tracking'
import { getUserViewHistoryData } from '@comp/page/user/browsing-history/ListingsHistory'
import { ClaimedCardSkeleton } from '@comp/page/common/card/user/ClaimedCard'
import { useMemo } from 'react'
import { houseToGta } from '@lib/utils'
import { map } from 'lodash-es'
import HomeItemCarousel from '../HomeItemCarousel'

function SimpleHouseCardItem({ data }) {
  if(data.historyCardType === 'loading') {
    return (
      <ClaimedCardSkeleton />
    )
  }

  if(data.historyCardType === 'nodata') {
    return (
      <div className="flex items-center justify-center h-[4.5rem] bg-cf0 rounded-md">
        <NoData />
      </div>
    )
  }
  
  return (
    <PropertyCard 
      house={data.house}
      type={data.type}
      cardWrapperClassName="border rounded-md h-full box-border overflow-hidden"
      slideChangeTrackingPageType="home"
    />
  )
}

export default function BrowsingHistory({
  title
}) {
  const { isMobile } = useApp()
  const { data, isLoading } = useFetch('user/history', { limit: 20 })
  const listings = useMemo(() => {
    if(isLoading) {
      return [
        { historyCardType: 'loading' },
        { historyCardType: 'loading' },
        { historyCardType: 'loading' },
      ]
    }

    const res = getUserViewHistoryData(data, isMobile)

    if(res.length === 0) {
      return [
        { historyCardType: 'nodata' },
      ]
    }
    
    return res
  }, [data, isLoading, isMobile])
  
  return (
    <>
      <HomeItemCarousel
        title={title}
        data={listings}
        SlideRender={SimpleHouseCardItem}
      />
      {!isLoading && (
        <>
          <UserTracking
            auOnly
            once
            eventName="homely_card_view"
            params={houseToGta(map(listings, 'house'), 'home', 'search_result')}
          />
          <UserTracking
            auOnly
            once
            eventName="homely_photo_view"
            params={houseToGta(map(listings, 'house'), 'home', 'search_result')}
          />
        </>
      )}
    </>
  )
}

BrowsingHistory.propTypes = {
  title: PropTypes.node,
}
