/**
 * ListingsHistory
 * 
 * @Author: Focci
 * @Date: 2023-09-06 10:19:30
 * @Last Modified by: Focci
 * @Last Modified time: 2023-09-06 10:19:30
 */

'use client'

// import PropTypes from 'prop-types'
import useFetch from '@hook/useFetch'
import Pagination from '@comp/Pagination'
import NoData from '@comp/NoData'
import useApp from '@hook/useApp'
import UserTracking from '@comp/user-tracking'
import { useLocale } from 'next-intl'
import { getListingData } from '@comp/page/search/get-listing-data'
import { houseSearchType, propertyType, typeOfId } from '@lib/config'
import { browsingHistory } from '@lib/route/profile'
import { VerticalCardSkeleton } from '@comp/page/common/card/property/CardSkeleton'
import { isWithinMonth } from '@lib/datetime'
import { isArray, times, filter, map } from 'lodash-es'
import { useEffect, useState, useCallback } from 'react'
import { fetchDelete, fetchProxy } from '@lib/fetch/client'
import { generateUrl, houseToGta, initialSearchParams } from '@lib/utils'
import ListBox from './ListBox'
import ListingsHistoryCard from './ListingsHistoryCard'

/**
 * 组织浏览记录列表数据
 */
export function getUserViewHistoryData(data, isMobile) {
  if(!isArray(data)) {
    return []
  }

  return data.map((item) => {
    const { id, views, house_search: hs, property_search: ps } = item

    let house = {}
    let type = ''

    // 估价或者售出
    if(!hs && ps) {
      house = { ...ps }
      // 最近售出，则标记为售出
      const isSold = house.latestSoldInfo?.transactionTime
        ? isWithinMonth(house.latestSoldInfo.transactionTime) 
        : false

      if(isSold) {
        type = houseSearchType.sold
        house.property_id = house.id
        house.street = house.address
        house.sold_price_label = house.latestSoldInfo?.transactionPrice
        house.sold_date = house.latestSoldInfo?.transactionTime
        house.type = { id: propertyType.SOLD }
      } else {
        type = houseSearchType.estimate
        house.unitary = house.title
      }
    } else {
      type = typeOfId[hs.type?.id]
      house = { ...hs }
    }
    
    const target = getListingData(house, type)
    const onSale = [
      houseSearchType.residential,
      houseSearchType.newHomes,
      houseSearchType.commercialForSale,
      houseSearchType.rural,
    ].indexOf(type) > -1
    
    house = {
      ...target,
      onSale,
      openHomeLabel: isMobile ? false : target.openHomeLabel,
      newListing: isMobile ? false : target.newListing,
      animation: false,
      isFeature: false,
    }

    return { house, type, id, views }
  })
}

export default function ListingsHistory({ 
  searchParams, 
  slug 
}) {
  const locale = useLocale()
  const { isMobile } = useApp()
  const [total, setTotal] = useState(0)
  const { defaultPage, pageSize, param, option } = initialSearchParams(searchParams)
  const { data, isLoading, mutate } = useFetch('user/history', param, option)

  const getPaginationItemUrl = useCallback(({ page }) => {
    const baseUrl = browsingHistory(slug, locale)
    const p = { ...searchParams, page }

    return generateUrl(baseUrl, p)
  }, [searchParams, slug, locale])

  // 删除
  const handleRemove = useCallback(({ recordingId, setLoading }) => {
    fetchProxy(fetchDelete(`user/history/${recordingId}`), setLoading).then(() => {
      const isAll = recordingId === 'all'
      setTotal((prev) => (isAll ? 0 : (prev - 1)))
      mutate(
        isAll ? [] : filter(data, (f) => f.id !== recordingId), 
        { revalidate: false }
      )
    })
  }, [data, mutate])

  const listings = getUserViewHistoryData(data, isMobile)

  // 设置数量
  useEffect(() => { data?.headers && setTotal(data.headers['x-total-count']) }, [data])

  // Loading
  if(isLoading) {
    return (
      <ListBox value="listings">
        <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-4">
          {times(3).fill('').map((_, index) => (
            <VerticalCardSkeleton key={index} />
          ))}
        </div>
      </ListBox>
    )
  }

  if(!total) {
    return (
      <ListBox value="listings">
        <div className="p-4">
          <NoData />
        </div>
      </ListBox>
    )
  }

  return (
    <ListBox 
      value="listings" 
      hasData={data?.length > 0} 
      onClean={handleRemove}
    >
      <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-4">
        {listings.map((item) => (
          <ListingsHistoryCard 
            house={item.house}
            type={item.type}
            recordingId={item.id}
            key={item.id}
            onRemove={handleRemove}
          />
        ))}
      </div>
      <UserTracking
        auOnly
        once
        eventName="homely_card_view"
        params={houseToGta(map(listings, 'house'), 'user_profile_view_history', 'search_result')}
      />
      <UserTracking
        auOnly
        once
        eventName="homely_photo_view"
        params={houseToGta(map(listings, 'house'), 'user_profile_view_history', 'search_result')}
      />
      <Pagination
        className="mt-8"
        page={defaultPage}
        pageSize={pageSize}
        getItemUrl={getPaginationItemUrl}
        total={total}
      />
    </ListBox>
  )
}

// ListingsHistory.propTypes = {
//   className: PropTypes.string,
// }
