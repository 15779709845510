/**
 * 我的收藏夹
 * 
 * @Author: Focci
 * @Date: 2023-08-21 09:47:37
 * @Last Modified by: Focci
 * @Last Modified time: 2023-08-21 09:47:37
 */

// import PropTypes from 'prop-types'
import useFetch from '@hook/useFetch'
import FavoriteCard, { FavoriteCardSkeleton } from '@comp/page/common/card/user/FavoriteCard'
import { isArray } from 'lodash-es'
import { useMemo } from 'react'
import HomeItemCarousel from '../HomeItemCarousel'
import FavoriteDefaultCard from './FavoriteDefaultCard'

function FavoriteCardItem({ data }) {
  if(data.favoriteCardType === 'more') {
    return <FavoriteDefaultCard />
  }
  if(data.favoriteCardType === 'loading') {
    return <FavoriteCardSkeleton />
  }
  return <FavoriteCard data={data} />
}

export default function HomeFavorite({
  title
}) {
  const { data, isLoading } = useFetch('folders')

  const listings = useMemo(() => {
    if(isLoading) {
      return [
        { favoriteCardType: 'loading' },
        { favoriteCardType: 'loading' },
        { favoriteCardType: 'loading' },
      ]
    }

    const result = isArray(data) ? data : []
    return [...result, { favoriteCardType: 'more' }]
  }, [data, isLoading])

  return (
    <HomeItemCarousel
      title={title}
      data={listings}
      SlideRender={FavoriteCardItem}
    />
  )
}

// HomeFavorite.propTypes = {
//   className: PropTypes.string,
// }
